import { Action } from "@solidjs/router";
import { Accessor, Show } from "solid-js";
import { useAppState } from "~/AppContext";

export default function KnowForm({
  data,
  action,
}: {
  data: Accessor<{ id?: string; essence?: string; body?: string } | undefined>;
  action: Action<[formData: FormData], void>;
}) {
  const { t } = useAppState();

  return (
    <Show when={data()}>
      <form action={action} method="post">
        <input type="hidden" name="id" value={data()!.id} />
        <h2>{t("proposition_form.essence_label")}</h2>
        <input
          type="text"
          id="essence"
          class="mt-2 border text-sm rounded-sm block w-full p-2.5 focus:ring-0 focus:border-gray-600"
          placeholder={t("proposition")}
          required
          value={data()?.essence || ""}
          name="essence"
        />
        <div class="w-full my-4 border border-gray-600 rounded-sm">
          <div class="px-2 py-2 rounded-b-lg ">
            <label for="editor" class="sr-only">
              Publish post
            </label>
            <textarea
              // @ts-ignore
              prop:value={data()!.body}
              id="editor"
              rows="8"
              class="block w-full px-0 text-sm focus:ring-0 border-0"
              placeholder={t("proposition_form.details_placeholder")}
              required
              name="body"
            />
          </div>
        </div>

        <button
          type="submit"
          class="inline-flex items-center px-5 py-2.5 text-sm font-medium text-center rounded-sm outline outline-1
        hover:dark:bg-gray-800 hover:bg-gray-100
        "
        >
          {t("save")}
        </button>
      </form>
    </Show>
  );
}
